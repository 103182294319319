import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "@components/layout"
import SEO from "@components/seo"
import marked from "marked"
import PageHeadingTitle from "@components/pageHeadingTitle"

class ProductTypeTemplate extends React.Component {
  render() {
    const { productType, allSanityBrand, applicationList } = this.props.data

    return (
      <Layout>
        <SEO
          title={productType.seo?.seoTitle || productType.title}
          description={productType.seo?.seoDescription || productType.excerpt}
        />
        <div className="page-headline pb-0">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-9">
                <PageHeadingTitle
                  h1={"Information Center"}
                  h2={productType.title}
                />
                {/* <span className="small">Posted on {post.publishedAt}</span> */}
                <div className="header-application text-center">
                  <p className="font-weight-bold text-primary h4">
                    Browse by Application
                  </p>
                  <ul className="list-inline application mb-0">
                    <li className="list-inline-item my-2">
                      <Link to="/products">
                        <span className="px-3 py-2 ">All</span>
                      </Link>
                    </li>
                    {applicationList.nodes.map(app => (
                      <li key={app.id} className="list-inline-item my-2">
                        <Link to={`/products/${app.slug.current}`}>
                          {" "}
                          <span className="px-3 py-2 ">{app.title}</span>
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="page-body productType">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-9">
                {productType.mainImage !== null ? (
                  <div className="text-center">
                    <img
                      src={
                        productType &&
                        productType.mainImage &&
                        productType.mainImage.asset &&
                        productType.mainImage.asset.fluid &&
                        productType.mainImage.asset.fluid.src
                      }
                      alt="Images"
                      className="img-fluid mb-4"
                    />
                  </div>
                ) : null}

                <div
                  dangerouslySetInnerHTML={{
                    __html: marked(productType.excerpt || ""),
                  }}
                />
                <div className="row mt-5 justify-content-center">
                  {allSanityBrand.nodes.map(brand => (
                    <div className="col-md-6 mb-5 brand">
                      <div className="blog-item bg-light rounded border">
                        <Link to={`/${brand.slug.current}`}>
                          <div className="blog-img-tile">
                            <div className="tile-bg-wrapper">
                              <span className="doc-icon">
                                <i className="fa fa-file-text"></i>
                              </span>
                              <div
                                className="tile-bg rounded-top"
                                style={{
                                  backgroundImage: `url(${brand.mainImage &&
                                    brand.mainImage.asset &&
                                    brand.mainImage.asset.fluid &&
                                    brand.mainImage.asset.fluid.src})`,
                                }}
                              ></div>
                            </div>
                          </div>
                        </Link>
                        <div className="my-3 px-3">
                          <h2 className="h4">
                            <Link to={`/${brand.slug.current}`} className="h4">
                              {brand.subtitle}
                            </Link>
                          </h2>
                          <p>{brand.excerpt}</p>
                        </div>
                        <Link
                          to={`/${brand.slug.current}`}
                          className="btn btn-secondary btn-secondary-red btn-sm m-3"
                        >
                          Read More
                        </Link>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default ProductTypeTemplate

export const producttypeQuery = graphql`
  query ProductTypeTemplateQuery($id: String!) {
    productType: sanityProductType(id: { eq: $id }) {
      id
      excerpt
      title
      seo {
        seoTitle
        seoDescription
      }
      mainImage {
        asset {
          fluid {
            src
          }
        }
      }
    }
    applicationList: allSanityApplication {
      nodes {
        id
        title
        excerpt
        slug {
          current
        }
      }
    }
    allSanityBrand(
      sort: { fields: order, order: ASC }
      filter: { productType: { elemMatch: { id: { eq: $id } } } }
    ) {
      nodes {
        title
        subtitle
        slug {
          current
        }
        mainImage {
          asset {
            fluid {
              src
            }
          }
        }
        id
        excerpt
      }
    }
  }
`
